import { Input } from "antd";
import { BaseNode, IDragDropHandleProps, INodeProps } from "../node/BaseNode";
import React, {
  ReactNode,
  SyntheticEvent,
  memo,
  useCallback,
  useState,
} from "react";
import { CompassOutlined, RightCircleOutlined } from "@ant-design/icons";
import {
  HandleType,
  Position,
  useReactFlow,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import TextArea from "antd/es/input/TextArea";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const updateNodeInternals = useUpdateNodeInternals();
    const store = useStoreApi();
    const [mappingHandles, setMappingHandles] = useState<string[]>(
      data["condition_mapping"]
        ? data["condition_mapping"]
            .split(",")
            .map((word: string) => word.trim())
            .filter((word: string) => word != "")
        : []
    );
    const handleChange = useCallback(
      (key: string, value: string) => {
        updateNodeInternals(id);
        setMappingHandles(
          value
            .split(",")
            .map((word) => word.trim())
            .filter((word) => word != "")
        );
        const { nodeInternals } = store.getState();
        setNodes(
          Array.from(nodeInternals.values()).map((node) => {
            if (node.id === id) {
              node.data = {
                ...node.data,
                [key]: value,
              };
            }

            return node;
          })
        );
      },
      [id, updateNodeInternals]
    );
    const dynamicHandleArray = mappingHandles.map(
      (handle, idx): IDragDropHandleProps => {
        return {
          id: `h_${handle}`,
          type: "source",
          position: Position.Bottom,
          label: handle,
          left: `${(100 * idx) / mappingHandles.length}`,
        };
      }
    );
    const inputNode: INodeProps = {
      id: id,
      label: "Condition Start",
      icon: <CompassOutlined />,
      headerColor: "rgb(228, 204, 255)",
      tagColor: "rgb(138, 56, 245)",
      nodeElements: [
        {
          key: "condition_mapping",
          label: "If Condition Mapping (Comma seperated)",
          element: (
            <>
              <Input
                className="nodrag nowheel"
                defaultValue={data["condition_mapping"]}
                onChange={(e) =>
                  handleChange("condition_mapping", e.target.value)
                }
              />
            </>
          ),
          dragDropHandles: [...dynamicHandleArray],
        },
      ],
      dragDropHandles: [
        {
          id: "parent_out",
          type: "source",
          position: Position.Left,
          label: "Out",
        },
        {
          id: "parent_in",
          type: "target",
          position: Position.Top,
          label: "In",
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
