import { FundProjectionScreenOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';

function ProjectButton({title,id,loading}:{title:string,id:string,loading:boolean}) {
    const navigate = useNavigate();
    function handleChangeProject(){
        navigate("/" + id+'/workflow');
    }
    return (
        <Button
            type="ghost"
            icon={<FundProjectionScreenOutlined />}
            onClick={()=>handleChangeProject()}
            loading={loading}
            style={{width:"150px"}}
        >
            {title}
        </Button>
    )
}

export default ProjectButton