import { RightCircleOutlined } from "@ant-design/icons";
import { Input, Tag } from "antd";
import React, { ReactNode, memo } from "react";
import { Handle, HandleType, Position, ReactFlowProvider } from "reactflow";
import InputNode from "./InputNode";
import OutputNode from "./OutputNode";
import OutputInputNode from "./OutputInputNode";
import LLMOpenAI from "./LLMOpenAI";
import EmbChroma from "./EmbChroma";
import DocumentLoader from "./DocumentLoader";
import "./BaseNode.css";
import QueryDataNode from "./QueryDataNode";
import NonEmptyData from "./utils/NonEmptyData";
import JsonKeyToValue from "./utils/JsonKeyToValue";
import SystemNode from "../nodes_v2/SystemNode";
import AssistantNode from "../nodes_v2/AssistantNode";
import UserNode from "../nodes_v2/UserNode";
import CondStartNode from "../nodes_v2/CondStartNode";
import CondEndNode from "../nodes_v2/CondEndNode";
import JsonNode from "../nodes_v2/JsonNode";
import FunctionNode from "../nodes_v2/FunctionNode";
import DBConnectionNode from "../nodes_v2/DBConnectionNode";
import GlobalVariablesNode from "../nodes_v2/GlobalVariablesNode";
import GetDataButton from "../../../utils/GetDataButton";

export interface IDragDropHandleProps {
  id?: string;
  type: HandleType;
  position: Position;
  top?: string;
  label?: string;
  left?: string;
}
interface INodeElementProps {
  label?: string;
  element: ReactNode;
  key: string;
  dragDropHandles: Array<IDragDropHandleProps>;
}
export interface INodeProps {
  id: string;
  label: string;
  headerColor?: string;
  tagColor?: string;
  icon?: ReactNode;
  nodeElements: Array<INodeElementProps>;
  dragDropHandles?: Array<IDragDropHandleProps>;
}

interface IBaseNodeProps {
  node: INodeProps;
  isConnectable: boolean;
  data: any;
}

export enum NodeType {
  AssistantNode = "assistantNode",
  SystemNode = "systemNode",
  UserNode = "userNode",
  CondStartNode = "condStartNode",
  CondEndNode = "condEndNode",
  JsonNode = "jsonNode",
  FunctionNode = "functionNode",
  DBConnectionNode = "dbConnectionNode",
  InputNode = "inputNode",
  OutputNode = "outputNode",
  OutputInputNode = "outputInputNode",
  LLMOpenAI = "llmOpenAI",
  EmbChroma = "embChroma",
  DocumentLoader = "documentLoader",
  QueryDataNode = "queryDataNode",
  NonEmptyData = "nonEmptyData",
  JsonKeyToValue = "jsonKeyToValue",
  GlobalVariablesNode = "globalVariablesNode",
}

export const nodeTypes: any = {
  [NodeType.AssistantNode]: AssistantNode,
  [NodeType.SystemNode]: SystemNode,
  [NodeType.UserNode]: UserNode,
  [NodeType.CondStartNode]: CondStartNode,
  [NodeType.CondEndNode]: CondEndNode,
  [NodeType.JsonNode]: JsonNode,
  [NodeType.FunctionNode]: FunctionNode,
  [NodeType.InputNode]: InputNode,
  [NodeType.OutputNode]: OutputNode,
  [NodeType.OutputInputNode]: OutputInputNode,
  [NodeType.LLMOpenAI]: LLMOpenAI,
  [NodeType.EmbChroma]: EmbChroma,
  [NodeType.DocumentLoader]: DocumentLoader,
  [NodeType.QueryDataNode]: QueryDataNode,
  [NodeType.NonEmptyData]: NonEmptyData,
  [NodeType.JsonKeyToValue]: JsonKeyToValue,
  [NodeType.DBConnectionNode]: DBConnectionNode,
  [NodeType.GlobalVariablesNode]: GlobalVariablesNode,
};

export const NodeIdPrifix: { [k: string]: string } = {
  [NodeType.AssistantNode]: "ass",
  [NodeType.SystemNode]: "sys",
  [NodeType.UserNode]: "usr",
  [NodeType.InputNode]: "inp",
  [NodeType.OutputNode]: "out",
  [NodeType.OutputInputNode]: "o_i",
  [NodeType.LLMOpenAI]: "llm",
  [NodeType.EmbChroma]: "emb",
  [NodeType.DocumentLoader]: "doc",
  [NodeType.QueryDataNode]: "que",
  [NodeType.NonEmptyData]: "ned",
  [NodeType.JsonKeyToValue]: "ktv",
  [NodeType.CondStartNode]: "cond_s",
  [NodeType.CondEndNode]: "cond_e",
  [NodeType.JsonNode]: "json",
  [NodeType.FunctionNode]: "func",
  [NodeType.DBConnectionNode]: "db",
  [NodeType.GlobalVariablesNode]: "glob",
};

export const BaseNode = (props: IBaseNodeProps) => {
  const { node, isConnectable, data } = props;
  const getHandle = (dragDropHandle: IDragDropHandleProps, idx: number) => {
    return (
      <Handle
        key={`handle_${idx}`}
        id={dragDropHandle.id}
        type={dragDropHandle.type}
        position={dragDropHandle.position}
        style={{
          background: "#555",
          top:
            dragDropHandle.position === Position.Left ||
            dragDropHandle.position === Position.Right
              ? dragDropHandle.top
                ? dragDropHandle.top + "%"
                : "50%"
              : "",
          left:
            dragDropHandle.position === Position.Bottom ||
            dragDropHandle.position === Position.Top
              ? dragDropHandle.left
                ? dragDropHandle.left + "%"
                : "50%"
              : "",
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
        children={
          <span
            style={{
              float:
                dragDropHandle.position === Position.Left ? "right" : "left",
              margin:
                dragDropHandle.position === Position.Left
                  ? "5px 10px"
                  : dragDropHandle.position === Position.Right
                  ? "5px 10px"
                  : dragDropHandle.position === Position.Top
                  ? "-20px 10px"
                  : "0px 10px",
            }}
          >
            {dragDropHandle.label}
          </span>
        }
      />
    );
  };
  return (
    <div
      style={{
        background: "#fff",
        border: "1px solid #777",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        minWidth: "350px",
      }}
    >
      <div
        style={{
          background: node.headerColor ? node.headerColor : "#91caff",
          borderRadius: "4px 4px 0 0",
          padding: "10px",
        }}
      >
        {node.icon}
        <span style={{ padding: "0 5px" }}>
          <b>{node.label}</b>
        </span>
        <Tag color={node.tagColor ? node.tagColor : "#108ee9"}>{node.id}</Tag>
      </div>
      {node.nodeElements.map((row, idx) => (
        <div
          key={`${row.label}_${idx}`}
          style={{
            padding: "10px 10px",
            display: "flex",
            flexDirection: "column",
            gap: 6,
            position: "relative",
          }}
        >
          <strong>{row.label}</strong>
          {row.element}
          {row.dragDropHandles.map((dragDropHandle, idx) =>
            getHandle(dragDropHandle, idx)
          )}
        </div>
      ))}
      <div
        key={`data`}
        style={{
          padding: "10px 10px",
          display: "flex",
          flexDirection: "column",
          gap: 6,
          position: "relative",
        }}
      >
        <GetDataButton
          id={node.id}
          temporary_value={data ? data["temporary_value"] : ""}
        />
      </div>
      {node.dragDropHandles?.map((dragDropHandle, idx) =>
        getHandle(dragDropHandle, idx)
      )}
    </div>
  );
};
