import { BaseNode, INodeProps } from "./BaseNode";
import React, { ReactNode, memo } from "react";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { HandleType, Position } from "reactflow";
import TextArea from "antd/es/input/TextArea";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const inputNode: INodeProps = {
      id: id,
      label: "Output",
      headerColor: "#b7eb8f",
      tagColor: "#389e0d",
      icon: <LeftCircleOutlined />,
      nodeElements: [
        {
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                value={data.result}
                autoSize={{ minRows: 3, maxRows: 8 }}
                readOnly
              />
            </>
          ),
          key: "output",
          dragDropHandles: [{ type: "target", position: Position.Left }],
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
