import { useEffect, useState } from 'react';
import { IHomeProps, Workflow } from '../workflow/Workflow';
import { Link, Route, Routes } from 'react-router-dom';
import { Button, Empty, Input, Space, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useAuth0 } from '@auth0/auth0-react';
import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { baseUrl } from '../prompt/Prompt';
import axios from 'axios';

interface DataType {
  id: string;
  name: string;
  creation_time_stamp: string;
  updation_time_stamp: string;
  global_variable: []
}

export const Project = (props: IHomeProps) => {
  const { setSliderElement } = props;
  const [projectList, setProjectList] = useState<DataType[]>([]);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [isProjectListLoading, setIsProjectListLoading] = useState(true);
  const [isProjectDuplicating, setIsProjectDuplicating] = useState(false);
  const [isProjectDeleting, setIsProjectDeleting] = useState(false)
  const [name, setName] = useState("")

  const columns: ColumnsType<DataType> = [
    {
      title: 'Project Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <Space size="middle">
          <Link to={`${record.id}/workflow`}>
            {name}
          </Link>
        </Space>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => (
          <div>{new Date(record.creation_time_stamp).toDateString()}</div>
      ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_, record) => (
        <div>{new Date(record.updation_time_stamp).toDateString()}</div>
    ),
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (_, record) => (
        <div>{record.id}</div>
    ),
    },
    {
      title: 'Global Variable',
      dataIndex: 'globalVariable',
      key: 'globalVariable',
      render: (_, record) => (
        <>
          {
            <div>{record.global_variable.join(", ")}</div>

          }
        </>
    ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="circle"
            loading={isProjectDuplicating}
            onClick={() => duplicateProject(record.id)}
            icon={<CopyOutlined />}
          ></Button>
          <Button
            type="primary"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={()=>deleteProject(record.id)}
            loading={isProjectDeleting}
          ></Button>
        </Space>
      ),
    },
  ];

  const duplicateProject = (id: string) => {
    setIsProjectDuplicating(true);
    const url=`https://ferrous.api.getcrux.ai/api/ferrous/project/${id}/copy/`
    axios
      .get(url)
      .then(function (response) {
        setIsProjectDuplicating(false);
        updateList()
      })
      .catch(function (error) {
        setIsProjectDuplicating(false);
      });
  };

  function deleteProject(id:string){
    setIsProjectDeleting(true);
    const url=`https://ferrous.api.getcrux.ai/api/ferrous/project/${id}/`
    axios
      .delete(url)
      .then(function (response) {
        console.log(response.data)
        setIsProjectDeleting(false);
        updateList();
      })
      .catch(function (error) {
        setIsProjectDeleting(false);
      });
  }

  function updateList(){
    setSliderElement(undefined);
    setIsProjectListLoading(true);
    const url='https://ferrous.api.getcrux.ai/api/ferrous/project/'
    axios
      .get(url)
      .then((res)=>{
        setProjectList(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
      .finally(()=>{
        setIsProjectListLoading(false)
      })
  }

  useEffect(() => {
    updateList()
  }, []);

  const createProject = () => {
    setIsCreatingProject(true);
    const url='https://ferrous.api.getcrux.ai/api/ferrous/project/'
    axios
      .post(url, {
        name:name,
        nodes:[],
        edges:[],
        structured_node_json:[]
      })
      .then(function (response) {
        setIsCreatingProject(false);
        updateList();
        // setExamples(response.data);
      })
      .catch(function (error) {
        setIsCreatingProject(false);
      });
      setName("")
  };

  return (
    <div>
      {isProjectListLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '100px',
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <div style={{display:"flex", gap:"10px", alignItems:"center", marginBottom:"10px"}}>
            <Input
              style={{width:"150px"}}
              className="nodrag nowheel"
              value={name}
              onChange={(e) =>
                setName(e.target.value)
              }
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              loading={isCreatingProject}
              onClick={createProject}
              disabled={name==""}
            >
              New Project
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={projectList}
            locale={{ emptyText: <Empty description={'No project'} /> }}
          />
        </>
      )}
    </div>
  );
};
