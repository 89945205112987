import { Input } from "antd";
import { BaseNode, INodeProps } from "../BaseNode";
import React, { ReactNode, SyntheticEvent, memo } from "react";
import { RightCircleOutlined } from "@ant-design/icons";
import { HandleType, Position, useReactFlow, useStoreApi } from "reactflow";
import TextArea from "antd/es/input/TextArea";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();

    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        })
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: "JsonKeyToValue",
      nodeElements: [
        {
          key: "key",
          label: "Key",
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data?.key}
                onChange={(e) => handleChange("key", e.target.value)}
                autoSize={{ minRows: 1, maxRows: 1 }}
              />
            </>
          ),
          dragDropHandles: [
            { type: "target", position: Position.Left, label: "Json Data" },
            { type: "source", position: Position.Right, label: "Value" },
          ],
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
