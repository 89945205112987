import { Alert, Button, Space, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import React, { useState } from "react";
import { useEdges, useNodes, useReactFlow, useStoreApi } from "reactflow";

const { Paragraph, Link } = Typography;

function GetDataButton({
  id,
  temporary_value,
}: {
  id: string;
  temporary_value: any;
}) {
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState<Record<string, any>>({
    temporary_value: temporary_value,
  });
  const [error, setError] = useState<any>(null);
  const nodes = useNodes();
  const edges = useEdges();
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const handleChange = (key: string, value: string | boolean) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === id) {
          node.data = {
            ...node.data,
            [key]: value,
          };
        }

        return node;
      })
    );
  };

  function getBaseId(nodeId: string, data: Record<string, any>) {
    let finalCount = 0;
    let finalNodeId = nodeId;
    let tempCount = 0;
    nodes.forEach((node) => {
      let tempNodeId = node.id;
      while (tempNodeId) {
        if (tempNodeId == nodeId) {
          if (tempCount >= finalCount) {
            finalNodeId = node.id;
            finalCount = tempCount;
          }
          break;
        }
        tempNodeId = data[tempNodeId].parent_node_id;
        tempCount++;
      }
    });
    return finalNodeId;
  }

  const data: Record<string, any> = {};
  // console.log(nodes);
  // console.log(edges);

  nodes.forEach((node) => {
    data[node.id] = JSON.parse(JSON.stringify(node.data));
  });

  edges.forEach((edge) => {
    const sourceHandle = edge.sourceHandle as string;
    const targetHandle = edge.targetHandle as string;
    const sourceType = sourceHandle.split("_")[0];
    const targetType = targetHandle.split("_")[0];
    if (targetType == "parent")
      data[edge.target]["parent_node_id"] = edge.source;
    if (targetType == "value" && sourceType == "value")
      data[edge.target]["value_node_id"] = edge.source;
    if (targetType == "variable" && sourceType == "variable") {
      if (!data[edge.target]["variable_node_ids"])
        data[edge.target]["variable_node_ids"] = [];
      data[edge.target]["variable_node_ids"].push(edge.source);
    }
  });
  edges.forEach((edge) => {
    const sourceHandle = edge.sourceHandle as string;
    const targetHandle = edge.targetHandle as string;
    const sourceType = sourceHandle.split("_")[0];
    const targetType = targetHandle.split("_")[0];
    if (sourceType == "h") {
      const handleId = sourceHandle.split("_")[1];
      if (!data[edge.source]["if_condition_mapping"])
        data[edge.source]["if_condition_mapping"] = {};
      data[edge.source]["if_condition_mapping"][handleId] = getBaseId(
        edge.target,
        data
      );
    }
  });
  const finalData = nodes.map((node) => {
    const typeOfNodes: Record<string, string> = {
      systemNode: "system",
      assistantNode: "assistant",
      userNode: "user",
      condStartNode: "cond-start",
      condEndNode: "cond-end",
      jsonNode: "json-extractor",
      functionNode: "function",
      dbConnectionNode: "sql-executor",
      globalVariablesNode: "global-variable",
    };
    return {
      id: node.id,
      type: typeOfNodes[node.type as string],
      ...data[node.id],
    };
  });
  function getData() {
    setLoading(true);
    setError(null);
    const url = "https://ferrous.api.getcrux.ai/api/ferrous/get-node-value/";
    axios
      .post(url, {
        nodes_json: finalData,
        node_id: id,
      })
      .then(function (response) {
        setResData({
          temporary_value: response.data["current_value"],
          message: response.data["message"],
        });
        handleChange("temporary_value", response.data["current_value"]);
      })
      .catch(function (error) {
        setError("something went wrong");
        const parser = new DOMParser();
        const errorHTML = parser.parseFromString(
          error.response.data,
          "text/html"
        );
        const tableRows = Array.from(errorHTML.querySelectorAll("tr"));
        for (const tableRow of tableRows) {
          const header = tableRow.querySelector("th");
          if (
            header &&
            header.textContent &&
            header.textContent.includes("Exception Value:")
          ) {
            const valueCell = header.nextElementSibling;
            const value =
              valueCell && valueCell.textContent
                ? valueCell.textContent.trim()
                : null;
            setError(value);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <Button
        type="primary"
        loading={loading}
        onClick={getData}
        disabled={resData["temporary_value"]}
      >
        {" "}
        Get Data{" "}
      </Button>
      {!loading && !error && resData && (
        <Space style={{ maxWidth: "500px" }} direction="vertical">
          <TextArea
            // readOnly={typeof resData["temporary_value"] == "object"}
            value={
              typeof resData["temporary_value"] == "object"
                ? JSON.stringify(resData["temporary_value"], null, 4)
                : resData["temporary_value"]
            }
            onChange={(e) => {
              handleChange("temporary_value", e.target.value);
              setResData({
                ...resData,
                temporary_value: e.target.value,
              });
            }}
          />
          <TextArea readOnly value={JSON.stringify(resData["message"])} />
        </Space>
      )}
      {error && (
        <Alert message="Error" description={error} type="error" showIcon />
      )}
    </>
  );
}

export default GetDataButton;
