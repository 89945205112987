import { Input, Tag } from "antd";
import { BaseNode, INodeProps } from "../node/BaseNode";
import React, {
  ReactNode,
  SyntheticEvent,
  memo,
  useEffect,
  useState,
} from "react";
import { CompassOutlined, RightCircleOutlined } from "@ant-design/icons";
import { HandleType, Position, useReactFlow, useStoreApi } from "reactflow";
import TextArea from "antd/es/input/TextArea";
import { Switch } from "antd";
import styled from "styled-components";
import GetDataButton from "../../../utils/GetDataButton";
const Toggle = styled(Switch)`
  &.ant-switch-checked {
    background-color: rgb(228, 204, 255);
  }
  &.ant-switch {
    width: 10px;
  }
  &.ant-switch:hover:not(.ant-switch-disabled) {
    background-color: rgb(228, 204, 255);
  }
`;

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const [edges, setEdges] = useState(store.getState().edges);
    useEffect(() => {
      const handleStoreChange = () => {
        setEdges(store.getState().edges.filter((edge) => edge.target === id));
      };
      const unsubscribe = store.subscribe(handleStoreChange);
      return () => {
        unsubscribe();
      };
    }, []);

    const handleChange = (key: string, value: string | boolean) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        })
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: "System",
      icon: <CompassOutlined />,
      headerColor: "rgb(228, 204, 255)",
      tagColor: "rgb(138, 56, 245)",
      nodeElements: [
        {
          key: "value",
          label: "Value",
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data["value"]}
                onChange={(e) => handleChange("value", e.target.value)}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </>
          ),
          dragDropHandles: [
            {
              type: "target",
              id: "value_in",
              position: Position.Left,
              label: "Value In",
            },
            {
              id: "value_out",
              type: "source",
              position: Position.Right,
              label: "Value Out",
            },
          ],
        },
        {
          key: "temporary",
          label: "Temporary",
          element: (
            <Toggle
              defaultChecked={data["temporary"]}
              onChange={(value) => handleChange("temporary", value)}
            />
          ),
          dragDropHandles: [
            {
              type: "target",
              id: "variable_in",
              position: Position.Left,
              label: "Variable In",
            },
            {
              id: "variable_out",
              type: "source",
              position: Position.Right,
              label: "Variable Out",
            },
          ],
        },
        {
          key: "variable_ids",
          label: "Variable Input Node IDs",
          element: (
            <>
              <div>
                {edges
                  .filter((edge) => {
                    const sourceHandle = edge.sourceHandle as string;
                    const targetHandle = edge.targetHandle as string;
                    const sourceType = sourceHandle.split("_")[0];
                    const targetType = targetHandle.split("_")[0];
                    return targetType == "variable" && sourceType == "variable";
                  })
                  .map((edge) => (
                    <Tag>{edge.source}</Tag>
                  ))}
              </div>
            </>
          ),
          dragDropHandles: [],
        },
      ],
      dragDropHandles: [
        {
          id: "parent_in",
          type: "target",
          position: Position.Top,
          label: "Parent In",
        },
        {
          id: "parent_out",
          type: "source",
          position: Position.Bottom,
          label: "Parent Out",
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
