import {
  CopyOutlined,
  DatabaseOutlined,
  MessageOutlined,
  SlidersOutlined,
  SwapOutlined,
  ToolOutlined,
} from "@ant-design/icons";

import Sider from "antd/es/layout/Sider";
import { Menu, MenuProps, theme } from "antd";
import "./WorkFlowSideBar.css";
import { NodeType } from "./node/BaseNode";

export const WorkFlowSideBar = () => {
  const workflowSliderItems: any = [
    {
      key: "0",
      label: "LLM",
      icon: <SwapOutlined />,
      children: [
        {
          key: "0_1",
          label: "System",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.SystemNode),
        },
        {
          key: "0_2",
          label: "User",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.UserNode),
        },
        {
          key: "0_3",
          label: "Assistant",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.AssistantNode),
        },
      ],
    },
    {
      key: "1",
      label: "Utils",
      icon: <SwapOutlined />,
      children: [
        {
          key: "1_0",
          label: "Condition Start",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.CondStartNode),
        },
        {
          key: "1_1",
          label: "Condition End",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.CondEndNode),
        },
        {
          key: "1_2",
          label: "JSON",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.JsonNode),
        },
        {
          key: "1_3",
          label: "Function",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.FunctionNode),
        },
        {
          key: "1_4",
          label: "DB Connection",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.DBConnectionNode),
        },
        {
          key: "1_5",
          label: "Global Variables",
          draggable: true,
          onDragStart: (event: React.DragEvent) =>
            onDragStart(event, NodeType.GlobalVariablesNode),
        },
      ],
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onDragStart = (event: React.DragEvent, nodeType: any) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <Sider collapsible style={{ background: colorBgContainer }}>
      <Menu
        // theme="dark"
        selectedKeys={[]}
        mode="inline"
        items={workflowSliderItems}
        defaultOpenKeys={["0", "1", "2", "3"]}
      />
    </Sider>
  );
};
