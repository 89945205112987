import { BaseNode, INodeProps } from "../node/BaseNode";
import { memo, useEffect, useState } from "react";
import {
  CompassOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Position, useReactFlow, useStoreApi } from "reactflow";
import GetDataButton from "../../../utils/GetDataButton";
import TextArea from "antd/es/input/TextArea";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const store = useStoreApi();
    const { setNodes } = useReactFlow();

    const handleChange = (key: string, value: string | boolean) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }
          return node;
        })
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: "Global Variables",
      icon: <CompassOutlined />,
      headerColor: "rgb(183, 248, 214)",
      tagColor: "rgb(62, 166, 65)",
      nodeElements: [
        {
          key: "global_variables",
          label: "Add variables",
          element: (
            <>
              <TextArea
                className="nodrag nowheel"
                defaultValue={data["value"]}
                onChange={(e) => handleChange("value", e.target.value)}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </>
          ),
          dragDropHandles: [],
        },
      ],
      dragDropHandles: [
        {
          id: "variable_out",
          type: "source",
          position: Position.Bottom,
          label: "Variable Out",
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
