import { Input, Tag } from "antd";
import { BaseNode, INodeProps } from "../node/BaseNode";
import { memo, useEffect, useState } from "react";
import { CompassOutlined } from "@ant-design/icons";
import { Position, useReactFlow, useStoreApi } from "reactflow";
import GetDataButton from "../../../utils/GetDataButton";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        })
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: "JSON",
      icon: <CompassOutlined />,
      headerColor: "rgb(183, 248, 214)",
      tagColor: "rgb(62, 166, 65)",
      nodeElements: [
        {
          key: "json_key",
          label: "JSON Key",
          element: (
            <>
              <Input
                className="nodrag nowheel"
                defaultValue={data["json_key"]}
                onChange={(e) => handleChange("json_key", e.target.value)}
              />
            </>
          ),
          dragDropHandles: [
            {
              id: "variable_out",
              type: "source",
              position: Position.Right,
              label: "Variable Out",
            },
          ],
        },
      ],
      dragDropHandles: [
        {
          id: "parent_out",
          type: "source",
          position: Position.Bottom,
          label: "Parent Out",
        },
        {
          id: "parent_in",
          type: "target",
          position: Position.Top,
          label: "Parent In",
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
