import { Input } from "antd";
import { BaseNode, IDragDropHandleProps, INodeProps } from "../node/BaseNode";
import React, {
  ReactNode,
  SyntheticEvent,
  memo,
  useCallback,
  useState,
} from "react";
import { CompassOutlined, RightCircleOutlined } from "@ant-design/icons";
import {
  HandleType,
  Position,
  useReactFlow,
  useStoreApi,
  useUpdateNodeInternals,
} from "reactflow";
import TextArea from "antd/es/input/TextArea";

export default memo(
  ({
    id,
    data,
    isConnectable,
  }: {
    id: string;
    data: any;
    isConnectable: boolean;
  }) => {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();
    const handleChange = (key: string, value: string) => {
      const { nodeInternals } = store.getState();
      setNodes(
        Array.from(nodeInternals.values()).map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              [key]: value,
            };
          }

          return node;
        })
      );
    };
    const inputNode: INodeProps = {
      id: id,
      label: "Condition End",
      icon: <CompassOutlined />,
      headerColor: "rgb(228, 204, 255)",
      tagColor: "rgb(138, 56, 245)",
      nodeElements: [],
      dragDropHandles: [
        {
          id: "parent_out",
          type: "source",
          position: Position.Bottom,
          label: "Out",
        },
        {
          id: "parent_in",
          type: "target",
          position: Position.Top,
          label: "In",
        },
      ],
    };
    return (
      <>
        <BaseNode node={inputNode} isConnectable={isConnectable} data={data} />
      </>
    );
  }
);
