import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  MiniMap,
  Controls,
  Background,
  ReactFlowProvider,
  OnInit,
  ReactFlowInstance,
  Node,
  XYPosition,
  Edge,
  MarkerType,
  ConnectionLineType,
  Handle,
  Position,
} from "reactflow";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import "reactflow/dist/style.css";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import {
  CopyOutlined,
  DesktopOutlined,
  FundProjectionScreenOutlined,
  PlayCircleOutlined,
  SaveOutlined,
  SwapOutlined,
  TabletOutlined,
} from "@ant-design/icons";
import { WorkFlowSideBar } from "./WorkFlowSideBar";
import InputNode from "./node/InputNode";
import OutputNode from "./node/OutputNode";
import { NodeIdPrifix, NodeType, nodeTypes } from "./node/BaseNode";
import { Button, FloatButton, Input, Select, Space, Spin } from "antd";
import axios from "axios";
import { baseUrl } from "../prompt/Prompt";
import { useNavigate, useParams } from "react-router-dom";
import { CustomInput } from "../../utils/CustomeInput";
import { json } from "stream/consumers";
import ProjectButton from "../../utils/ProjectButton";

export interface IHomeProps {
  setSliderElement: (element: ReactNode) => void;
}

export const Workflow = (props: IHomeProps) => {
  const { setSliderElement } = props;
  // const { projectId, workflowId } = useParams();
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance<any, any>>();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isWorkflowRunning, setIsWorkflowRunning] = useState(false);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(false);
  const [isWorkflowUpdating, setIsWorkflowUpdating] = useState(false);
  const [isWorkflowNameUpdating, setIsWorkflowNameUpdating] = useState(false);
  const [workFlowName, setWorkFlowName] = useState("");
  const [name, setName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const { projectId } = useParams();

  const getId = (nodeType: NodeType, prifix: string) => {
    console.log(
      nodes,
      nodeType,
      nodes.filter((row) => row.type === nodeType)
    );
    var nodeTmpIdArray = nodes
      .filter((row) => row.type === nodeType)
      .map((row) => parseInt(row.id.split("_").pop()!));
    return `${prifix}_${
      nodeTmpIdArray.length > 0 ? Math.max(...nodeTmpIdArray) + 1 : 0
    }`;
  };

  useEffect(() => {
    if (projectId) {
      setIsWorkflowLoading(true);
      setSelectedProject(projectId as string);
      const url = `https://ferrous.api.getcrux.ai/api/ferrous/project/${projectId}/`;
      axios
        .get(url)
        .then(function (response) {
          setNodes(response.data.nodes);
          setEdges(response.data.edges);
          setName(response.data.name);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsWorkflowLoading(false);
        });
    }
  }, [projectId]);

  useEffect(() => {
    setSliderElement(<WorkFlowSideBar />);
    // axios
    //   .get(`${baseUrl}/dev-ops-lab/v1/workflow/${workflowId}/`)
    //   .then(function (response) {
    //     setIsWorkflowLoading(false);
    //     setWorkFlowName(response.data.project_name);
    //     setNodes(response.data.nodes);
    //     setEdges(response.data.edges);
    //     console.log(response.data.nodes);
    //     // setExamples(response.data);
    //   })
    //   .catch(function (error) {
    //     setIsWorkflowLoading(false);
    //   });
  }, []);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      const reactFlowBounds =
        reactFlowWrapper?.current?.getBoundingClientRect();
      const type: string = event.dataTransfer.getData("application/reactflow");

      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position: XYPosition = reactFlowInstance!.project({
        x: event.clientX - reactFlowBounds!.left,
        y: event.clientY - reactFlowBounds!.top,
      });
      const newNode: Node = {
        id: getId(type as NodeType, NodeIdPrifix[type]),
        type,
        position,
        data: {},
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, nodes]
  );

  const onConnect = useCallback(
    (params: any) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  function getBaseId(nodeId: string, data: Record<string, any>) {
    let finalCount = 0;
    let finalNodeId = nodeId;
    let tempCount = 0;
    nodes.forEach((node) => {
      let tempNodeId = node.id;
      while (tempNodeId) {
        if (tempNodeId == nodeId) {
          if (tempCount >= finalCount) {
            finalNodeId = node.id;
            finalCount = tempCount;
          }
          break;
        }
        tempNodeId = data[tempNodeId].parent_node_id;
        tempCount++;
      }
    });
    return finalNodeId;
  }

  const runWorkflow = () => {
    const data: Record<string, any> = {};
    nodes.forEach((node) => {
      data[node.id] = JSON.parse(JSON.stringify(node.data));
    });
  
    edges.forEach((edge) => {
      const sourceHandle = edge.sourceHandle as string;
      const targetHandle = edge.targetHandle as string;
      const sourceType = sourceHandle.split("_")[0];
      const targetType = targetHandle.split("_")[0];
      if (targetType == "parent") data[edge.target]["parent_node_id"] = edge.source;
      if (targetType == "value" && sourceType == "value") data[edge.target]["value_node_id"] = edge.source;
      if (targetType == "variable" && sourceType == "variable"){
        if(!data[edge.target]["variable_node_ids"]) data[edge.target]["variable_node_ids"]=[]
        data[edge.target]["variable_node_ids"].push(edge.source)
      }
    });
    edges.forEach((edge) => {
      const sourceHandle = edge.sourceHandle as string;
      const targetHandle = edge.targetHandle as string;
      const sourceType = sourceHandle.split("_")[0];
      const targetType = targetHandle.split("_")[0];
      if (sourceType == "h") {
        const handleId = sourceHandle.split("_")[1];
        if (!data[edge.source]["if_condition_mapping"])
          data[edge.source]["if_condition_mapping"] = {};
        data[edge.source]["if_condition_mapping"][handleId] = getBaseId(edge.target,data);
      }
    });
    const finalData = nodes.map((node) => {
      const typeOfNodes:Record<string,string> = {
        systemNode: "system",
        assistantNode: "assistant",
        userNode: "user",
        condStartNode: "cond-start",
        condEndNode: "cond-end",
        jsonNode: "json-extractor",
        functionNode: "function",
        dbConnectionNode: 'sql-executor',
        globalVariablesNode: "global-variable"
      };
      return {
        id: node.id,
        type: typeOfNodes[node.type as string],
        ...data[node.id],
      };
    });
    
    return finalData
  };
  const updateWorkflow = () => {
    if (projectId) {
      setIsWorkflowUpdating(true);
      const url = `https://ferrous.api.getcrux.ai/api/ferrous/project/${projectId}/`;
      axios
        .put(url, {
          name: name,
          nodes: nodes,
          edges: edges,
          structured_node_json: runWorkflow()
        })
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          setIsWorkflowUpdating(false);
        });
    }
  };

  const updateWorkflowName = (value: string) => {
    // setIsWorkflowNameUpdating(true);
    // axios
    //   .put(`${baseUrl}/dev-ops-lab/v1/project/${projectId}/`, {
    //     name: value,
    //   })
    //   .then(function (response) {
    //     setIsWorkflowNameUpdating(false);
    //   })
    //   .catch(function (error) {
    //     setIsWorkflowNameUpdating(false);
    //   });
  };

  return (
    <>
      <ReactFlowProvider>
        {isWorkflowLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "100px",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100%", position: "relative" }}
            ref={reactFlowWrapper}
          >
            <ReactFlow
              minZoom={0.1}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              onDragOver={onDragOver}
              nodeTypes={nodeTypes}
              attributionPosition="top-right"
              defaultEdgeOptions={{
                type: "smoothstep",
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                },
              }}
            >
              <Controls />
              <Background color="#aaa" gap={16} />
            </ReactFlow>
            {/* <Input
              placeholder="Nodes"
              value={JSON.stringify(nodes)}
              onChange={(e) => {
                setNodes(JSON.parse(e.target.value));
              }}
            />
            <br />
            <Input
              placeholder="Edges"
              value={JSON.stringify(edges)}
              onChange={(e) => {
                setEdges(JSON.parse(e.target.value));
              }}
            /> */}

            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <CustomInput
                value={workFlowName}
                isUpdating={isWorkflowNameUpdating}
                updateValue={updateWorkflowName}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "flex",
                gap: 5,
              }}
            >
              <Space wrap>
                <Select
                  defaultValue="Select"
                  value={selectedProject}
                  onChange={(value) => setSelectedProject(value)}
                  options={[
                    {
                      value: "451efc4d-8d29-46d7-983a-7079f2b4052d",
                      label: (
                        <ProjectButton
                          loading={isWorkflowLoading}
                          title="Dev"
                          id="451efc4d-8d29-46d7-983a-7079f2b4052d"
                        />
                      ),
                    },
                    {
                      value: "239b2511-2777-4966-b6c0-99c93cbf9de6",
                      label: (
                        <ProjectButton
                          loading={isWorkflowLoading}
                          title="Don't update 1"
                          id="239b2511-2777-4966-b6c0-99c93cbf9de6"
                        />
                      ),
                    },
                    {
                      value: "4a3011d4-71bc-4f3c-9024-5ad1ebe57aa2",
                      label: (
                        <ProjectButton
                          loading={isWorkflowLoading}
                          title="Don't update 2"
                          id="4a3011d4-71bc-4f3c-9024-5ad1ebe57aa2"
                        />
                      ),
                    },
                    {
                      value: "f2e68e61-5eff-4163-ac1f-c46abd85cc07",
                      label: (
                        <ProjectButton
                          loading={isWorkflowLoading}
                          title="Don't update 3"
                          id="f2e68e61-5eff-4163-ac1f-c46abd85cc07"
                        />
                      ),
                    },
                  ]}
                />
              </Space>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={updateWorkflow}
                loading={isWorkflowUpdating}
              >
                Update
              </Button>
              {/* <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={runWorkflow}
                loading={isWorkflowRunning}
              >
                Copy Json
              </Button> */}
              {/* <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={updateWorkflow}
                loading={isWorkflowUpdating}
              >
                Update
              </Button> */}
              {/* <Button
                type="primary"
                icon={<DesktopOutlined />}
                // onClick={updateWorkflow}
                loading={isWorkflowUpdating}
              >
                Export UI
              </Button> */}
            </div>
          </div>
        )}
      </ReactFlowProvider>
    </>
  );
};
